<template>
  <Modal
    contentClass="w-1/3"
    header="Change Company Status"
    @close="$emit('close', false)"
  >
    <form class="w-full">
      <div class="border-b pb-2 mx-4">
        <p class="text-xm p-2">Company Name</p>
        <p class="text-xm opacity-75">{{ companystatusData("name") }}</p>
      </div>
      <div class="border-b pb-2 mx-4">
        <p class="text-xm p-2">Current Status</p>
        <p class="text-xm opacity-75">{{ companystatusData("status") }}</p>
      </div>
      <DropDown
        v-model="companyStatus"
        icon="person-outline"
        :options="status"
        label="Company status"
      />
      <div class="flex w-full space-x-2 justify-center mt-10">
        <button class="button">Change Status</button>
      </div>
    </form>
  </Modal>
</template>
<script>
import { updateCompanyStatus } from "@/requests"
export default {
  data() {
    return {
      // status: ["Whitelist", "Blacklist", "Pending"],
      status: [],
      companyStatus: ""
    }
  },
  props: ["name", "selectedCompany"],

  beforeMount() {
    console.log(777, this.selectedCompany)
    this.changeCompanyStatus()
  },
  computed: {
    companyId() {
      return this.$route.params.companyId
    }
  },
  methods: {
    changeCompanyStatus() {
       console.log(1010, this.companystatusData('name'))
      if (this.companystatusData('status') === "whitelist") {
        this.status = ["Blacklist"]
        return;
      } else if (this.companystatusData('status') === "blacklist") {
        this.status = ["Whitelist"]
        return;
      }
      this.status = ["Whitelist", "Blacklist"]
    },
    companystatusData(key) {
      return this.selectedCompany?.[key] ?? "N/A"
    },
    test() {
      console.log(9900, this.companyId)
    },
    update() {
      updateCompanyStatus(this.companyId.companyId, {
        ...this.companyId
      }).then((response) => {
        console.log(2200, response)

        this.statusData = response.data
      })
    }
  }
}
</script>
